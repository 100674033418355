import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { AngularContext, AngularServicesContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { CombinedCourse, RootState } from 'redux/schemas';
import {
  getCurrentLecture, getFullCourseTimelineContent, getFullCourseTimelineProgress,
  resetPortableCompletionPanelData,
  resetTimelineCachedCourseLookUp,
  resetTimelineCachedCourseLookUpProgress,
  selectPortableCompletionPanelTab,
} from 'redux/actions/timeline';
import { Enrollment } from 'redux/schemas/models/my-account';
import { CompletionCriteriaType } from 'redux/schemas/app/timeline';
import { getCurrentCourse } from 'redux/selectors/course';
import { getCourseTimelineCache } from 'redux/selectors/timeline';

import TimelineTabsHeader from './timeline-tabs-header';
import ApiWrapper from '../completion_criteria/api-wrapper';
import { PortableCompletionHeader } from '../completion_criteria/portable-completion-panel';
import Outline from './tabs/outline';
import Todos from '../completion_criteria/portable-completion-panel/content-tabs/todos';
import Points from '../completion_criteria/portable-completion-panel/content-tabs/points';
import Assignments from '../completion_criteria/portable-completion-panel/content-tabs/assignments';
import useCompletionCriteria from '../completion_criteria/use-completion-criteria';

export enum Tab {
  OUTLINE = 'outline',
  TODOS = 'todos',
  POINTS = 'points',
  TODOS_FOR_COMPLETION = 'todos_for_completion',
  ASSIGNMENTS = 'assignments',
}

type CourseTimelineContextType = {
  showOutline: boolean,
  isFullTimeline: true,
  vieweeId?: number,
  defaultTab?: Tab,
  courseCompletion?: {
    status?: number,
    progress?: string,
  }
  isLearnerProgressModal?: true,
};

export const CourseTimelineContext = React.createContext<CourseTimelineContextType>(null);

const CompletionConfetti = () => {
  const { isLearnerProgressModal } = useContext(CourseTimelineContext) || {};
  const { injectServices } = useContext(AngularContext);
  const [CourseHomeManager, ConfettiAnimation] = injectServices(['CourseHomeManager', 'ConfettiAnimation']);
  const { isCompleted } = useCompletionCriteria();
  const currentCourse: CombinedCourse = useSelector(getCurrentCourse);
  const userCourse: Enrollment = useSelector((state: RootState) => state.models.enrollments[currentCourse.userCourse]);
  const isCourseCompletionProgressLoading = useSelector((state: RootState) => state.app.timeline.isCourseCompletionProgressLoading);
  const isTimelineContentLoaded = useSelector(state => state.app.lecturePage.isTimelineContentLoaded);
  const completionStatus = userCourse?.completionStatus; // 0 - Not completed, 1 - Auto, 2 - Manual

  const confettiShown = useRef(false);

  useEffect(() => {
    if (confettiShown.current // Confetti is shown before
      || isLearnerProgressModal // No need to show confetti in Learner progress modal
      || isCourseCompletionProgressLoading // Course completion is loading
      || !isTimelineContentLoaded // Timeline content is not loaded
    ) {
      return;
    }

    if (completionStatus
      || CourseHomeManager?.courseHome?.showStatementOfAccomplishment
      || isCompleted
    ) {
      ConfettiAnimation?.showConfetti();
      confettiShown.current = true;
    }
  }, [
    ConfettiAnimation, isLearnerProgressModal,
    CourseHomeManager?.courseHome?.showStatementOfAccomplishment,
    completionStatus, isCompleted,
    isTimelineContentLoaded, isCourseCompletionProgressLoading,
  ]);

  return null;
};

const CourseTimeline = () => {
  const { defaultTab, vieweeId, isLearnerProgressModal } = useContext(CourseTimelineContext) || {};
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(defaultTab || Tab.OUTLINE);
  const lastActiveTabRef = useRef(activeTab);

  const { $scope } = React.useContext(AngularServicesContext);

  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const currentCourse = useSelector(getCurrentCourse);
  const { isTimelineContentLoaded } = useSelector(state => state.app.lecturePage);
  const isCurrentLectureLoading = useSelector((state) => state.app.timeline.isCurrentLectureLoading);
  const currentTimelineLecturePage = useSelector((state) => state.app.timeline.currentLecturePage);
  const lecturePage = useSelector((state) => state.models.lecturePages[currentTimelineLecturePage?.currentLecture?.activityId]);
  const portableCompletionPanelSelectedTab: CompletionCriteriaType = useSelector(
    (state: RootState) => state.app.timeline.portableCompletionPanelSelectedTab,
  );
  const courseTimelineCache = useSelector((state) => getCourseTimelineCache(state, catalogId));

  const {
    automatedCompletionsEnabled,
    enabledFactorCount,
  } = useCompletionCriteria();

  // First get the content
  useEffect(() => {
    if (!courseTimelineCache?.content) {
      dispatch(getFullCourseTimelineContent({
        catalogId,
      }));
    }
  }, [dispatch, catalogId, courseTimelineCache?.content]);

  // Get the current lecture too
  useEffect(() => {
    if (!isLearnerProgressModal) {
      // Get current lecture page
      dispatch(getCurrentLecture({
        catalogId,
      }));
    }
  }, [catalogId, dispatch, isLearnerProgressModal]);

  // Get the progress prioritising the current section
  useEffect(() => {
    let fullCourseTimelineProgressPromise;
    if (isTimelineContentLoaded && !isCurrentLectureLoading && !courseTimelineCache?.progress) {
      fullCourseTimelineProgressPromise = dispatch(getFullCourseTimelineProgress({
        catalogId,
        sectionId: lecturePage?.lectureSectionId,
        vieweeId,
      }));
    }

    return () => {
      if (fullCourseTimelineProgressPromise) {
        fullCourseTimelineProgressPromise.abort();
      }
    };
  }, [
    isTimelineContentLoaded, dispatch, catalogId, lecturePage?.lectureSectionId,
    isCurrentLectureLoading, vieweeId, courseTimelineCache?.progress,
  ]);

  // Need to listen the portable completion panel selection and show tabs
  // accordingly
  useEffect(() => {
    if (portableCompletionPanelSelectedTab === CompletionCriteriaType.POINTS) {
      setActiveTab(Tab.POINTS);
    } else if (portableCompletionPanelSelectedTab === CompletionCriteriaType.TODOS) {
      setActiveTab(Tab.TODOS_FOR_COMPLETION);
    } else if (portableCompletionPanelSelectedTab === CompletionCriteriaType.ASSIGNMENTS) {
      setActiveTab(Tab.ASSIGNMENTS);
    }
  }, [portableCompletionPanelSelectedTab]);

  // Set the Gauge selected state according to the active tab
  useEffect(() => {
    if (activeTab === Tab.OUTLINE || activeTab === Tab.TODOS) {
      // For the outline and todos tab, we need to reset the completion
      // criteria panel selection
      dispatch(
        selectPortableCompletionPanelTab(null),
      );
    } else if (activeTab === Tab.POINTS) {
      // When selecting the points tab need to make the points gauge selected
      // in the completion panel
      dispatch(
        selectPortableCompletionPanelTab(CompletionCriteriaType.POINTS),
      );
    } else if (activeTab === Tab.TODOS_FOR_COMPLETION) {
      dispatch(
        selectPortableCompletionPanelTab(CompletionCriteriaType.TODOS),
      );
    } else if (activeTab === Tab.ASSIGNMENTS) {
      dispatch(
        selectPortableCompletionPanelTab(CompletionCriteriaType.ASSIGNMENTS),
      );
    }
  }, [activeTab, dispatch]);

  // This is to handle a special case, user clicks on the completion gauge again
  // which need to go back to Outline.
  useEffect(() => {
    if (!portableCompletionPanelSelectedTab
      && [Tab.TODOS_FOR_COMPLETION, Tab.ASSIGNMENTS, Tab.POINTS].includes(lastActiveTabRef.current)) {
      setActiveTab(Tab.OUTLINE);
    }
  }, [portableCompletionPanelSelectedTab]);

  // This lastActiveTabRef is to handle the special case above. We can't use
  // activeTab as it will create infinite effect loop
  useEffect(() => {
    lastActiveTabRef.current = activeTab;

    return () => {
      lastActiveTabRef.current = null;
    };
  }, [activeTab]);

  useEffect(() => () => {
    // Clear all the data
    dispatch(resetPortableCompletionPanelData(catalogId));
  }, [dispatch, catalogId]);

  // Reset the portableCompletionPanelTab, when exiting
  useEffect(() => () => {
    dispatch(selectPortableCompletionPanelTab(null));
  }, [dispatch]);

  // Resetting the timeline course cache when navigating to edit mode.
  useEffect(() => () => {
    if ($scope.StateManager.lastStateAttempted?.name === 'course-home-edit') {
      dispatch(resetTimelineCachedCourseLookUp(catalogId));
    }
  }, [$scope.StateManager.lastStateAttempted, catalogId, dispatch]);

  // If we are exiting from Learner Progress modal, reset the progress lookup
  // as the user may be visiting another learners progress or the user will
  // return to course timeline. In both cases the progress need to reloaded
  useEffect(() => () => {
    if (isLearnerProgressModal) {
      dispatch(resetTimelineCachedCourseLookUpProgress(catalogId));
    }
  }, [dispatch, catalogId, isLearnerProgressModal]);

  // The selection in the tabs is different with completion criteria selected
  const getSelectedTabInHeader = useCallback(() => {
    if (portableCompletionPanelSelectedTab === CompletionCriteriaType.POINTS) {
      return Tab.POINTS;
    }
    if (portableCompletionPanelSelectedTab === CompletionCriteriaType.TODOS) {
      return Tab.TODOS;
    }
    if (portableCompletionPanelSelectedTab === CompletionCriteriaType.ASSIGNMENTS) {
      return Tab.TODOS;
    }
    return activeTab;
  }, [activeTab, portableCompletionPanelSelectedTab]);

  const selectedTabInHeader = getSelectedTabInHeader();

  if (currentCourse.inArchiveMode && !isLearnerProgressModal) {
    return (
      <ApiWrapper>
        <CompletionConfetti />
        <PortableCompletionHeader />
      </ApiWrapper>
    );
  }

  return (
    <ApiWrapper>
      <CompletionConfetti />
      <TimelineTabsHeader activeTab={selectedTabInHeader} onTabClick={setActiveTab} />
      {(!currentCourse.isProgram && automatedCompletionsEnabled && enabledFactorCount > 0)
        && <PortableCompletionHeader />}
      <div className='body-text-wrapper pb-6'>
        {activeTab === Tab.OUTLINE && <Outline />}
        {activeTab === Tab.TODOS && <Todos onlyForCompletion={false} />}
        {activeTab === Tab.POINTS && <Points />}
        {activeTab === Tab.TODOS_FOR_COMPLETION && (
          <Todos
            onlyForCompletion
            onViewAllTodos={() => setActiveTab(Tab.TODOS)}
          />
        )}
        {activeTab === Tab.ASSIGNMENTS && <Assignments />}
      </div>
    </ApiWrapper>
  );
};

export default CourseTimeline;
