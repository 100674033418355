import omit from 'lodash/omit';
import { normalize } from 'normalizr';
import mergeWith from 'lodash/mergeWith';

import { TimelineSchema, TimelineEntities, TimelineNormalizedResult } from 'redux/schemas/api/timelines';
import { createReducer } from '@reduxjs/toolkit';
import { ActivityKey } from 'redux/schemas/models/activity';
import merge from 'lodash/merge';
import isBoolean from 'lodash/isBoolean';
import _ from 'underscore';
import { NLecturePage } from 'redux/schemas/models/lecture-page';
import { replaceArrays } from 'shared/lodash-utils';
import { LectureSection } from 'redux/schemas/models/lecture-section';
import { LectureSectionSchema } from 'redux/schemas/api/lecture-sections';
import {
  deleteLectureSection, getCourseLecturePageTimeline, getFullCourseTimelineWithProgress,
  getTimelineSectionProgress, getTimelineSubsectionSummary, getFullCourseTimelineContent,
  getCourseCompletionProgress,
  togglePortableCompletionPanel,
  selectPortableCompletionPanelTab,
  getTimelineTodosList,
  getTimelineAssignmentsList,
  getTimelinePointsList,
  resetPortableCompletionPanelData,
  getAwardedPoints,
  getCurrentLecture,
  resetShowPortableCompletionPanel,
  resetIsTimelineContentLoaded,
  resetTimelineTodo,
  getTimelineWithCommunications,
  getFullCourseTimelineProgress,
  resetTimelineCachedCourseLookUp,
  resetTimelineCachedCourseLookUpProgress,
} from 'redux/actions/timeline';
import { CompletionCriteriaType } from 'redux/schemas/app/timeline';
import { initialRootState } from '.';

export type TimelineCachedCourseLookUpState = {
  content: boolean,
  progress: boolean,
};

export type AppTimelineState = {
  loadingLectureSections: Record<number, boolean>,
  loadingLectureSectionsSummaries: Record<number, boolean>,
  showPortableCompletionPanel: boolean,
  portableCompletionPanelSelectedTab: CompletionCriteriaType,
  isCourseCompletionProgressLoading: boolean,
  isCurrentLectureLoading: boolean,
  todos: {
    loadingTimelineTodos: boolean,
    hasMore: boolean,
    lastLoadedPage: number,
    // To retain the last loaded todo list mode. Will be resetted if the
    // requested mode is different from last loaded mode
    lastLoadedMode: 'all' | 'required',
  },
  assignments: {
    loadingTimelineAssignments: boolean,
    hasMore: boolean,
    lastLoadedPage: number,
  },
  points: {
    loadingTimelinePoints: boolean,
    hasMore: boolean,
    lastLoadedPage: number,
  },
  currentLecturePage: {
    currentLecture: {
      actionName: string,
      activityDeliverableType: string,
      activityId: number,
      activityType: string,
      happenedAt: string,
      title: string,
    },
    firstLecture: {
      id: number,
      title: string,
      type: string,
    },
  },
  cachedCourseLookUp: {
    [catalogId: string]: TimelineCachedCourseLookUpState,
  },
};

export const appTimelineInitialState: AppTimelineState = {
  loadingLectureSections: {},
  loadingLectureSectionsSummaries: {},
  showPortableCompletionPanel: false,
  portableCompletionPanelSelectedTab: null,
  isCourseCompletionProgressLoading: false,
  isCurrentLectureLoading: false,
  todos: {
    loadingTimelineTodos: false,
    hasMore: true, // Set as true initially to fetch the first page
    lastLoadedPage: null,
    lastLoadedMode: null,
  },
  assignments: {
    loadingTimelineAssignments: false,
    hasMore: true, // Set as true initially to fetch the first page
    lastLoadedPage: null,
  },
  points: {
    loadingTimelinePoints: false,
    hasMore: true, // Set as true initially to fetch the first page
    lastLoadedPage: null,
  },
  currentLecturePage: {
    currentLecture: null,
    firstLecture: null,
  },
  cachedCourseLookUp: {},
};

export default createReducer(initialRootState, builder => {
  builder.addCase(getFullCourseTimelineWithProgress.pending, (state, action) => {
    state.app.lecturePage.isTimelineContentLoaded = false;
  });

  const timelineHandler = (state, action) => {
    const { payload } = action;

    /**
     * In collection course we are considering folders as sub sections, but BE
     * returns the folders as sections so we are interchanging the fields here.
     */
    if (state.models.courses[action.meta.arg.catalogId]?.isContentManagementCollection) {
      payload.lectureSubsections = payload.lectureSections;
      payload.lectureSections = [];
    }

    const normalizedData = normalize<TimelineNormalizedResult, TimelineEntities>(payload, TimelineSchema);
    const { lecturePages, lectureSections, lectureComponents, skillTaggings, ...restEntities } = omit(normalizedData.entities, Object.values(ActivityKey));

    const params = action.meta.arg;

    const timelineSubsections = payload.lectureSubsections.map(lss => lss.id);
    const timelineSections = payload.lectureSections.map(ls => ls.id);

    state.models.timelines[action.meta.arg.catalogId] = {
      lectureSubsections: timelineSubsections,
      lectureSections: timelineSections,
      awardedPoints: [],
    };

    // TODO: There is a bug here; elsewhere in the app we store/normalize courses
    // by their id, not catalog id. We need to either correct the data loading for institutions/courses
    // to use catalogId, or change this to use id
    const course = state.models.courses[params.catalogId];
    course.lectureSubsectionIds = normalizedData.result.lectureSubsections;
    course.lectureSectionIds = normalizedData.result.lectureSections;

    // Merge the normalized entity values for the activities into the redux store
    const dataModels = (Object.values(ActivityKey) as string[]);
    dataModels.forEach(key => {
      // TODO: We probably want to rethink how we merge in this timeline data. When this data is received
      // after having inserted lecture page data to the store, it will overwrite existing activity objects
      // with incomplete versions (from the timeline) unless we iterate through every activity object like so
      Object.keys(normalizedData.entities?.[key] ?? {}).forEach(entityKey => {
        // if (!state.models[key][entityKey]) {
        // TODO: I've seen issues where merging this data causes props to be overwritten with null values because the right-most object here does not have
        // data for that prop. We need to write some kind of "smart merge" function
        // that will zip up both data objects to always take the prop values that are truthy,
        // and likely defer to the object coming from the non-lite outline call if there's
        // disagreement
        if (!state.models[key][entityKey]) {
          state.models[key][entityKey] = normalizedData.entities[key][entityKey];
        }
      });
    });

    /* Merge the lecture sections and lecture page data.
     The course_outline.json requrest handled in this reducer returns data containing serialized lecture page &
     lecture sectionn objects. The lecture page objects are largely (but not exactly) supersets of the data returned
     by /{lecturePageId}.json; the exceptions are in a few properties like 'has_structural_issues' and 'has_timeline_issues' which
     are present in both API respsonses but only seem to ever have a value in the /course_outline.json responses.

     They also handle lecture_components differently; /course_outline.json only includes components that are "activities" and thus
     relevant to the outline, and even then they only have a subset of data */
    Object.keys(lectureSections ?? {}).forEach(modelKey => {
      // Do a simple assignment and avoide merging if the data isn't already in redux
      if (!state.models.lectureSections[modelKey]) {
        state.models.lectureSections[modelKey] = lectureSections[modelKey];
        return;
      }

      /** Clearing the lecture page and sub section fields before merging the response
       * data. If not, the deleted items are not removed from the merged items */
      state.models.lectureSections[modelKey].lecturePages = [];
      state.models.lectureSections[modelKey].lectureSubsections = [];
      state.models.lectureSections[modelKey].unreleasedLecturePages = [];

      merge(state.models.lectureSections[modelKey], lectureSections[modelKey]);
    });

    Object.keys(lecturePages ?? {}).forEach(modelKey => {
      const existingLecturePageData = state.models.lecturePages[modelKey];

      let incomingLecturePageData: Partial<NLecturePage> = lecturePages[modelKey];

      if (existingLecturePageData) {
        incomingLecturePageData = _.omit(
          incomingLecturePageData,
          // Only overwriting lecture page lectureComponents property if
          // updateLectureComponentData is true. This because response only
          // lists activity lecture component ids, ignoring non-activities. It
          // should be used carefully.
          action.meta.arg.updateLectureComponentData && (existingLecturePageData.id !== state.app.lecturePage.currentLectureId) ? '' : 'lectureComponents',
          // Omitting course because of this https://github.com/novoed/NovoEdWeb/blob/4c85d17df9939bffcb5fa2745b53226462af9d73/app/redux/reducers/timeline.ts#L45
          'course',
        );
      }

      /**
       * Avoid rewriting null value on the course field if the existing data
       * has course and incoming data has null value on course.
       */
      if (!_.isEmpty(existingLecturePageData?.course)
        && _.isEmpty(incomingLecturePageData?.course)) {
        incomingLecturePageData = _.omit(incomingLecturePageData, 'course');
      }

      if (existingLecturePageData) {
        /**
         * StudentPrerequisites already updated based on the lecture API.
         * The timeline API is not updating the StudentPrerequisites correctly.
         * So ignoring the studentPrerequisites on here.
         */
        mergeWith(existingLecturePageData, _.omit(
          incomingLecturePageData,
          ['studentPrerequisites', 'prerequisite', 'usersRecentlyViewed', 'numUsersRecentlyViewed'],
        ), replaceArrays);
      } else {
        state.models.lecturePages[modelKey] = incomingLecturePageData as NLecturePage;
      }
    });

    // Only copy over lecture components if they don't already have entries in Redux; the LC data
    // from this response is always just a subset of LC data retrieved from getLectureComponent and
    // also contains some incorrect false-y values
    Object.keys(lectureComponents ?? {}).forEach(componentKey => {
      if (!state.models.lectureComponents[componentKey]) {
        state.models.lectureComponents[componentKey] = lectureComponents[componentKey];
      }
    });

    Object.keys(skillTaggings ?? {}).forEach(skillTaggingKey => {
      // BE return hasRatings as null in course timeline response.
      // Ignoring the key if is not boolean on ourside.
      if (!isBoolean(skillTaggings[skillTaggingKey].hasRatings)) {
        delete skillTaggings[skillTaggingKey].hasRatings;
      }

      state.models.skillTaggings[skillTaggingKey] = {
        ...state.models.skillTaggings[skillTaggingKey],
        ...skillTaggings[skillTaggingKey],
      };
    });

    mergeWith(state.models, restEntities, replaceArrays);

    state.app.courseCommunications[params.catalogId].automatedCommunications.isTimelineLoaded = true;

    state.app.lecturePage.isTimelineContentLoaded = true;

    if (!state.app.timeline.cachedCourseLookUp[params.catalogId]) {
      state.app.timeline.cachedCourseLookUp[params.catalogId] = {};
    }
    state.app.timeline.cachedCourseLookUp[params.catalogId].content = true;
  };
  builder.addCase(getFullCourseTimelineWithProgress.fulfilled, timelineHandler)
    .addCase(getTimelineWithCommunications.fulfilled, timelineHandler)
    .addCase(getCourseLecturePageTimeline.fulfilled, (state, action) => {
    // Only overwrite specific props from the response onto the current lecture page object. For some reason
    // a large serialization is given in the response but much of it is unnecessary
      const lecturePage = state.models.lecturePages[action.meta.arg.lecturePageId];
      Object.assign(lecturePage, _.pick(action.payload, ['completed']));
    })
    .addCase(deleteLectureSection.fulfilled, (state, action) => {
      const { lectureSectionId: deletedSectionId, catalogId } = action.meta.arg;

      const parentSection = _.find(state.models.lectureSections, (ls) => _.some(ls.lectureSubsections, subsections => (subsections === deletedSectionId)));
      if (!_.isEmpty(parentSection)) {
        const subSectionIndex = parentSection.lectureSubsections.indexOf(deletedSectionId);
        parentSection.lectureSubsections.splice(subSectionIndex, 1);
      }

      // Remove the deleted section id if it has no parent section or sub section.
      const timelineSectionIndex = state.models.timelines[catalogId]?.lectureSections.indexOf(deletedSectionId);
      const timelineSubSectionIndex = state.models.timelines[catalogId]?.lectureSubsections.indexOf(deletedSectionId);
      if (timelineSectionIndex && timelineSectionIndex !== -1) {
        state.models.timelines[catalogId].lectureSections.splice(timelineSectionIndex, 1);
      } else if (timelineSubSectionIndex && timelineSubSectionIndex !== -1) {
        state.models.timelines[catalogId].lectureSubsections.splice(timelineSubSectionIndex, 1);
      }

      delete state.models.lectureSections[action.meta.arg.lectureSectionId];
    })
    .addCase(resetIsTimelineContentLoaded, (state) => {
      state.app.lecturePage.isTimelineContentLoaded = false;
    })
    .addCase(getFullCourseTimelineContent.pending, (state, action) => {
      /**
       * The loaded state and its associated loaders are not required when
       * making requests from the LHS, so the value is not set.
       */
      if (!action.meta.arg.isFromLhs) {
        state.app.lecturePage.isTimelineContentLoaded = false;
      }
    })
    .addCase(getFullCourseTimelineContent.fulfilled, timelineHandler)
    .addCase(getTimelineSectionProgress.pending, (state, action) => {
      state.app.timeline.loadingLectureSections[action.meta.arg.sectionId] = true;
    })
    .addCase(getTimelineSectionProgress.fulfilled, (state, action) => {
      const normalizedData = normalize<LectureSection, TimelineEntities>(action.payload, LectureSectionSchema);
      state.app.timeline.loadingLectureSections[action.meta.arg.sectionId] = false;
      mergeWith(state.models, normalizedData.entities, replaceArrays);
    })
    .addCase(getTimelineSectionProgress.rejected, (state, action) => {
      state.app.timeline.loadingLectureSections[action.meta.arg.sectionId] = false;
    })
    .addCase(getTimelineSubsectionSummary.pending, (state, action) => {
      action.meta.arg.subsectionIds.forEach((id) => {
        state.app.timeline.loadingLectureSectionsSummaries[id] = true;
      });
    })
    .addCase(getTimelineSubsectionSummary.fulfilled, (state, action) => {
      action.meta.arg.subsectionIds.forEach((id) => {
        state.app.timeline.loadingLectureSectionsSummaries[id] = false;

        action.payload.forEach((subsection) => {
          Object.assign(state.models.lectureSections[subsection.id], subsection);
        });
      });
    })
    .addCase(getFullCourseTimelineProgress.fulfilled, (state, action) => {
      state.app.timeline.cachedCourseLookUp[action.meta.arg.catalogId].progress = true;
    })
    .addCase(getTimelineSubsectionSummary.rejected, (state, action) => {
      action.meta.arg.subsectionIds.forEach((id) => {
        state.app.timeline.loadingLectureSectionsSummaries[id] = false;
      });
    })
    .addCase(getCurrentLecture.pending, (state) => {
      state.app.timeline.isCurrentLectureLoading = true;
    })
    .addCase(getCurrentLecture.fulfilled, (state, action) => {
      state.app.timeline.currentLecturePage = action.payload.result;
      state.app.timeline.isCurrentLectureLoading = false;
    })
    .addCase(getCourseCompletionProgress.pending, (state) => {
      state.app.timeline.isCourseCompletionProgressLoading = true;
    })
    .addCase(getCourseCompletionProgress.fulfilled, (state, action) => {
      const course = state.models.courses[action.meta.arg.catalogId];
      course.automaticCompletionProgress = {
        todosCompleted: action.payload.todosCompleted ?? 0,
        pointsReceived: action.payload.pointsReceived ?? 0,
        todoAssignmentsCompleted: action.payload.todoAssignmentsCompleted ?? 0,
        requiredTodosCompleted: action.payload.requiredTodosCompleted ?? 0,
      };
      state.app.timeline.isCourseCompletionProgressLoading = false;
    })
    .addCase(resetTimelineCachedCourseLookUp, (state, action) => {
      state.app.timeline.cachedCourseLookUp[action.payload] = {
        content: false,
        progress: false,
      };
    })
    .addCase(resetTimelineCachedCourseLookUpProgress, (state, action) => {
      if (state.app.timeline.cachedCourseLookUp[action.payload]) {
        state.app.timeline.cachedCourseLookUp[action.payload].progress = false;
      }
      state.app.timeline.cachedCourseLookUp[action.payload] = null;
    })
    .addCase(togglePortableCompletionPanel, (state) => {
      state.app.timeline.showPortableCompletionPanel = !state.app.timeline.showPortableCompletionPanel;
    })
    .addCase(resetShowPortableCompletionPanel, (state) => {
      state.app.timeline.showPortableCompletionPanel = false;
    })
    .addCase(selectPortableCompletionPanelTab, (state, action) => {
      state.app.timeline.portableCompletionPanelSelectedTab = action.payload;
    })
    .addCase(resetPortableCompletionPanelData, (state, action) => {
      state.app.timeline = {
        ...state.app.timeline,
        todos: {
          loadingTimelineTodos: false,
          hasMore: true,
          lastLoadedPage: null,
          lastLoadedMode: null,
        },
        assignments: {
          loadingTimelineAssignments: false,
          hasMore: true,
          lastLoadedPage: null,
        },
        points: {
          loadingTimelinePoints: false,
          hasMore: true,
          lastLoadedPage: null,
        },
      };
      const catalogId = action.payload;
      if (state.models.timelines[catalogId]) {
        state.models.timelines[catalogId].todoActivities = null;
        state.models.timelines[catalogId].assignmentActivities = null;
        state.models.timelines[catalogId].pointActivities = null;
        state.models.timelines[catalogId].awardedPoints = null;
      }
    })
    .addCase(resetTimelineTodo, (state, action) => {
      state.app.timeline.todos.hasMore = true;
      state.app.timeline.todos.lastLoadedPage = null;
      if (state.models.timelines[action.payload.catalogId]) {
        state.models.timelines[action.payload.catalogId].todoActivities = null;
      }
    })
    .addCase(getTimelineTodosList.pending, (state, action) => {
      state.app.timeline.todos.loadingTimelineTodos = true;
    })
    .addCase(getTimelineTodosList.fulfilled, (state, action) => {
      state.app.timeline.todos.hasMore = action.payload.todos.length >= action.payload.perPage;
      // Not normalizing activities as the activities doesn't have a unique id
      // The uniqueness of the id can be determined only by concatinating it
      // with the activity type
      if (!state.models.timelines[action.meta.arg.catalogId].todoActivities) {
        state.models.timelines[action.meta.arg.catalogId].todoActivities = [];
      }
      state.models.timelines[action.meta.arg.catalogId].todoActivities = [
        ...state.models.timelines[action.meta.arg.catalogId].todoActivities,
        ...action.payload.todos,
      ];
      state.app.timeline.todos.lastLoadedPage = action.meta.arg.page;
      state.app.timeline.todos.lastLoadedMode = action.meta.arg.onlyForCompletion ? 'required' : 'all';
      state.app.timeline.todos.loadingTimelineTodos = false;
    })
    .addCase(getTimelineAssignmentsList.pending, (state, action) => {
      state.app.timeline.assignments.loadingTimelineAssignments = true;
    })
    .addCase(getTimelineAssignmentsList.fulfilled, (state, action) => {
      state.app.timeline.assignments.hasMore = action.payload.todos.length >= action.payload.perPage;
      if (!state.models.timelines[action.meta.arg.catalogId].assignmentActivities) {
        state.models.timelines[action.meta.arg.catalogId].assignmentActivities = [];
      }
      state.models.timelines[action.meta.arg.catalogId].assignmentActivities = [
        ...state.models.timelines[action.meta.arg.catalogId].assignmentActivities,
        ...action.payload.todos,
      ];
      state.app.timeline.assignments.lastLoadedPage = action.meta.arg.page;
      state.app.timeline.assignments.loadingTimelineAssignments = false;
    })
    .addCase(getTimelinePointsList.pending, (state, action) => {
      state.app.timeline.points.loadingTimelinePoints = true;
    })
    .addCase(getTimelinePointsList.fulfilled, (state, action) => {
      state.app.timeline.points.hasMore = action.payload.todos.length >= action.payload.perPage;
      if (!state.models.timelines[action.meta.arg.catalogId].pointActivities) {
        state.models.timelines[action.meta.arg.catalogId].pointActivities = [];
      }
      state.models.timelines[action.meta.arg.catalogId].pointActivities = [
        ...state.models.timelines[action.meta.arg.catalogId].pointActivities,
        ...action.payload.todos,
      ];
      state.app.timeline.points.lastLoadedPage = action.meta.arg.page;
      state.app.timeline.points.loadingTimelinePoints = false;
    })
    .addCase(getAwardedPoints.fulfilled, (state, action) => {
      state.models.timelines[action.meta.arg.catalogId].awardedPoints = action.payload;
    });
});
